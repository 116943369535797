
import { defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "Home",
  setup() {
    const store = useStore();
    const top1 = "55Usfk_vdss"; // 29 ソーラーキッチンカー(3)
    const top2_1 = "rdwKUDe59y8"; // 8 田の守り人
    const top2_2 = "MTizZE8FROc"; //  Dear My Child
    const total_eth = store.state.total_eth;
    return {
      total_eth,
      top1,
      top2_1,
      top2_2,
    };
  }
});
