
import { defineComponent } from "vue";
import { initializeEthereum } from "./utils/MetaMask";

export default defineComponent({
  setup() {
    initializeEthereum();
    return {};
  },
});
