export const firebaseConfig = {
  apiKey: "AIzaSyAW5wA3DRn2R12h_gh_kRyKDzNcm1OUl6U",
  authDomain: "nounsfes.firebaseapp.com",
  projectId: "nounsfes",
  storageBucket: "nounsfes.appspot.com",
  messagingSenderId: "670587195151",
  appId: "1:670587195151:web:0f959c3014383d185d387a",
  measurementId: "G-G22ZT64FDJ",
  useEmulator:false,
};
export const vote_event = {
  id:"0005",
  start:new Date("2022-11-19T09:00:00.000+09:00"),
  end:new Date("2022-11-24T09:00:00.000+09:00"),
  selections : [
    { key:"rdwKUDe59y8", id: 8},
    { key:"jv6hKO0hsF0", id: 2},
    { key:"OUu89Cy9FcU", id: 22},
    { key:"B8MG06Qrxmc", id: 23},
    { key:"CWm_rDfFo0g", id: 24},
    { key:"nWByl12F91U", id: 26},
    { key:"Z0eImePeSNk", id: 27},
    { key:"55Usfk_vdss", id: 29},
    { key:"izoa6o9cIyY", id: 30},
    { key:"iZX_MhsvcDo", id: 36},
    { key:"_vgq3J66_tA", id: 38},
    { key:"9GDBhj0hlLw", id: 39},
    { key:"OMyrEf269qA", id: 45},
    { key:"MTizZE8FROc", id: 46},
    { key:"hm1jpwf_dmY", id: 47},
  ]
};