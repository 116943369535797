
import { onMounted, defineComponent, computed, watch, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { ethers } from "ethers";
import {
  doc,
  getDoc,
  getDocs,
  collection,
} from "firebase/firestore";
import { db } from "@/utils/firebase";
import { ChainIds, switchNetwork } from "../utils/MetaMask";
import {vote_event} from "@/config/project";
import {vote} from "@/utils/functions";

const ERC721 = {
  wabi: require("../abis/AbstractERC721.json"), // wrapped abi
  address: "0xb1b25eeb1026cb947b3f65a5cc123fc28b13eee6"
};
const OpenSeaERC1155 = {
  abi: require("../abis/OpenSeaERC1155.json"),
  address: "0x2953399124F0cBB46d2CbACD8A89cF0599974963"
};

// Because OpenSea chose to put all Polygon NFTs in a single ERC1155 contract, 
// we need to perform this hack in order to know the number of NFTs in user's wallet. 
const itemId0 = ethers.BigNumber.from("35416128211843416333493280670751952307736614476901985064732031611086890336257");
const itemId1 = ethers.BigNumber.from("35416128211843416333493280670751952307736614476901985064732031612186401964033");
const delta = itemId1.sub(itemId0);
const itemCount = 79;
const itemIds = [...Array(itemCount).keys()].map((value) => { return itemId0.add(ethers.BigNumber.from(delta.mul(value))); });

export default defineComponent({
  name: "HomePage",
  setup() {
    const store = useStore();
    const namedNounCount = ref(0);
    const nounsCount = ref(0);
    const showCount = false; //if shows vote result as pulbic, set true

    onMounted(async () => {
      await updateUserStatus();
      if(showCount) await updateCount(); 
    });

    const updateUserStatus = async ()=> {
      console.log(store.state.user);
        if(!store.state.user){
          return;
        }
        try {
          const docu = await getDoc(doc(db, `users/${store.state.user.uid}/private/votes`)); 
          if (docu.exists()) {
            console.log(docu.data());
            if(docu.data().voted[vote_event.id]){
              isVoted.value = true;
            }
          }
        } catch(e) {
          console.error("watch user", e);
        }
    };

    const isVoted = ref(false);
    watch(
      () => store.state.user,
      async () => {
        updateUserStatus();
      }
    );
    interface Selection {
      id : number,
      key: string,
      selected:boolean,
      count: number
    }
    const selections = ref<Selection[]>([]);
    console.log(Object.assign([],vote_event.selections));
    selections.value = Object.assign([],vote_event.selections).map((a:{id:number,key:string})=>{
      return {id:a.id, key:a.key, selected:false, count:0};
    });
    const isSelected = (i:number) => {
      const index = selections.value.findIndex(a=>a.id == i);
      return selections.value[index].selected;
    };
    const setSelected = (i:number) => {
      for (const s of selections.value) {
        s.selected = false;
      }
      const index = selections.value.findIndex(a=>a.id == i);
      selections.value[index].selected = true;


      const message = lang.value == "en" ? 
      "Once voted, it cannot be changed. Do you really want to vote?" :
       "一度投票したら変更できません。投票してよいですか？"
      if (window.confirm(message)) {      
        callVote();
      } else {
        selections.value[index].selected = false;
      }
    };
    const getSelected = () => {
      return selections.value.filter((i) => i.selected);
    };
    const totalcount = computed(() => selections.value.map(c=>c.count).reduce((p,c)=>p+c));

    const updateCount = async () => {
      try {
        const results = await getDocs(collection(db, `vote_events/${vote_event.id}/results`));      
        console.log(results)
        for (const doc of results.docs) {
          console.log(doc.id);
          const index = selections.value.findIndex(a=>a.id == Number(doc.id));
          const data = doc.data() as {counter:number};
          selections.value[index].count = data.counter;
        }
      } catch(e) {
        console.error("updateCount", e);
      }
    };

    const errorMessage = ref("")
    const isVoting = ref(false);
    const callVote = async () => {
      const selected = getSelected()[0];
      if(!selected){
        errorMessage.value = "please select the video";
        return;
      }
      console.log(selected);
      interface voteResult{ data:{result:boolean, message:string }}
      const token = 0 < nounsCount.value ? "nounsLove" : 
          0 < namedNounCount.value ? "namedNoun" : "";
      try {
        isVoting.value = true;
        const ret:voteResult= await vote({
          voteEventId: vote_event.id,
          selectionId: selected.id,
          uid: store.state.account,
          token
        }) as voteResult;
        if(ret.data?.result){
          isVoted.value = true;
          if(showCount) await updateCount();
        } else {
          console.error(ret.data);
          errorMessage.value = ret.data.message;
        }
        isVoting.value = false;
        errorMessage.value = "";
      } catch(e) {
        console.error("callVote", e);
        isVoting.value = false;
        errorMessage.value = e as string;
      }
    };
    const user = computed(() => store.state.user);
    const isVoteReady = computed(() => 
      store.state.user && 
      !isVoting.value &&
      !isVoted.value && 
      (vote_event.start < new Date() && new Date() < vote_event.end) &&
      (0 < namedNounCount.value || 0 < nounsCount.value));


    const raised_eth = store.state.raised_eth;
    const i18n = useI18n();
    const lang = computed(() => {
      return i18n.locale.value;
    });
    const tokenGate = computed(() => {
      const account = store.state.account;
      const chainId = store.state.chainId;
      if (!store.getters.hasMetaMask) {
        return "pleaseInstall";
      }
      const provider = new ethers.providers.Web3Provider(store.state.ethereum);
      // provider is sufficient for read-only contract, but we use signer for future enhancement
      const signer = provider.getSigner();

      console.log("** recomputing", store.getters.displayAccount || "N/A", chainId);
      if (!account) {
        return "pleaseConnect";
      }
      if (chainId == ChainIds.Mainnet) {
        const fetchNounsToken = async() => {
          const nounsToken = new ethers.Contract(ERC721.address, ERC721.wabi.abi, signer);
          const result = await nounsToken.functions.balanceOf(account);
          nounsCount.value = result[0].toNumber();
        };
        fetchNounsToken();
        return "switchNetwork";
      }
      if (chainId != ChainIds.Polygon) {
        return "switchNetwork";
      }
      const fetchNamedNoun = async () => {
        const namedNoun = new ethers.Contract(OpenSeaERC1155.address, OpenSeaERC1155.abi, signer);
        const accounts = itemIds.map(() => {return account;});
        try {
          const results = await namedNoun.functions.balanceOfBatch(accounts, itemIds) as Array<Array<ethers.BigNumber>>;
          const count = results[0].reduce((total, result) => {
            return total.add(result);
          }, ethers.BigNumber.from(0));
          namedNounCount.value = count.toNumber();
        } catch(e) {
          console.error("fetchInfo", e);
        }
      };
      fetchNamedNoun();
      return "active";
    });
    const switchToPolygon = async () => {
      console.log("switchToPolygon called");
      await switchNetwork(ChainIds.Polygon);
    }
    const displayAccount = computed(() => {
      return store.getters.displayAccount;
    });
    
    return {
      vote_event,
      isVoteReady,
      isVoted,
      isVoting,
      selections,
      isSelected,
      setSelected,
      getSelected,
      callVote,
      totalcount,
      errorMessage,
      user,
      displayAccount,
      namedNounCount,
      nounsCount,
      tokenGate,
      lang,
      raised_eth,
      switchToPolygon
    };
  }
});
